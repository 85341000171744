<template>
  <base-layout>
    <add-multiple-scooter />
    <add-single-scooter />

    <VehicleIndex
      :fst-id="`ebikes`"
      :page-title="`E-Bikes`"
      :endpoint="getEndpoint"
      :qso="getEndpointOptions"
      :tableHeaders="tableHeaders"
      @add:single="add('single')"
      @add:multiple="add('multiple')"
      @edit="edit"
    />
  </base-layout>
</template>

<script>
import { useEndpoints } from '@/composables'
import { EventBus } from '@/utils/EventBus'
import { MultipleScooterConfig } from '@/config/MultipleScooterConfig'
import { SingleScooterConfig } from '@/config/SingleScooterConfig'
import { VehicleIndex } from '@/composites/vehicle'
export default {
  name: 'EBikeIndex',

  components: {
    BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
    AddSingleScooter: () => import('./AddSingleScooter.vue'),
    AddMultipleScooter: () => import('./AddMultipleScooter.vue'),
    VehicleIndex,
  },

  data() {
    return {
      is_edit: false,
      tableHeaders: [
        { text: 'Name', width: '10%', sort: null },
        { text: 'Battery', width: '10%', sort: 'lock__power_level' },
        { text: 'IMEI', width: '10%', sort: null },
        { text: 'QRCode', width: '10%', sort: null },
        { text: 'Last Connected', width: '13%', sort: null },
        { text: 'Last Located', width: '13%', sort: null },
        { text: 'Flags', width: '12%', sort: null },
        { text: 'Heart Beat', width: '8%', sort: null },
        { text: 'Last Location', width: '9%', sort: null },
        { text: 'Actions', width: '5%', sort: null },
      ],
    }
  },

  computed: {
    getEndpoint() {
      return useEndpoints.vehicle.index() + '?bike_category=E&'
    },

    getEndpointOptions() {
      return {
        prepend: '',
        append: '',
      }
    },
  },

  methods: {
    add(type) {
      if (type == 'single') {
        console.log('single = ', SingleScooterConfig.events.sorToggle)
        EventBus.$emit(SingleScooterConfig.events.editingData, {})
        dispatchEvent(new Event(SingleScooterConfig.events.sorToggle))
      } else {
        EventBus.$emit(MultipleScooterConfig.events.editingData, {})
        dispatchEvent(new Event(MultipleScooterConfig.events.sorToggle))
      }
      this.is_edit = false
      this.dropdownPopoverShow = false
    },

    edit({ vehicle }) {
      console.log(vehicle)
      this.is_edit = true
      EventBus.$emit(SingleScooterConfig.events.editingData, vehicle)
      dispatchEvent(new Event(SingleScooterConfig.events.sorToggle))
    },
  },
}
</script>
